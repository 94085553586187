const ChaleesHeartIcon = ({ size = "normal" }: { size?: "small" | "normal" }) => (
  <svg
    width={size === "small" ? 26 * 0.75 : 26}
    height={size === "small" ? 29 * 0.75 : 29}
    viewBox="8 10 52 58"
  >
    <path
      id="Shape"
      d="M35.255 66 C34.512 66 30.588 62.685 26.535 58.632 22.465 54.562 17.944 48.856 16.433 45.882 14.929 42.922 13.097 38.25 12.361 35.5 11.626 32.75 11.018 29.005 11.012 27.178 11.005 25.351 12.145 21.902 13.545 19.514 14.945 17.125 17.179 14.458 18.51 13.586 19.841 12.714 22.34 12 24.064 12 26.265 12 28.042 12.904 30.034 15.036 31.593 16.706 34.013 24.935 35 27 36.794 30.755 36.043 30.228 38 25 38.865 22.69 41.201 16.263 42.243 14.75 43.82 12.46 44.865 12 48.497 12 51.055 12 53.482 12.625 54.369 13.512 55.201 14.344 56.19 17.087 56.568 19.608 56.947 22.129 57.004 26.317 56.697 28.914 56.389 31.511 54.929 36.654 53.452 40.343 51.976 44.032 48.975 49.851 46.783 53.275 44.592 56.699 41.406 60.963 39.704 62.75 38.001 64.537 35.999 66 35.255 66 Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="3"
      strokeOpacity="1"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
  </svg>
)

export default ChaleesHeartIcon
